.multi-select .dropdown-heading {
    height: 46px !important;
    padding: 0.375rem 0.75rem;
}

.multi-select .dropdown-heading .dropdown-heading-value > span {
    line-height: 42px !important;
}

.unitBox {
    width: 100%;
    box-shadow: 0 3px 6px #a2a2a2;
    border-radius: 36px;
    background-color: #ffffff;
    margin-bottom: 30px;
    padding: 15px 20px 30px;
    position: relative;
    font-size: 16pt;
}

.unitBox .head .lead {
    font-size: 20pt;
    color: #565656;
}

.unitBox hr {
    border: 1px solid #8089ff;
    margin: 15px 0px;
}

.unitLessons .col-md-6 {
    margin-bottom: 10px;
}
